<template>
  <div class="bg-white mb-5 p-4">
    <div v-if="mode === 'type-selection'">
      <BackButton v-if="activity || media" @click="handleBackButton" />

      <TypeSelector
        class="my-3"
        id="type-selector"
        :title="$t('sparkmembers.contents.views.editor.components.lesson.components.main-media.title')"
        :types="lessonKind === 'online_course' ? types : activityType"
        @select="typeSelected"
        @premium="$emit('premium', $event)"
      />
    </div>

    <ActivityEditor
      :activity="activity"
      v-if="mode === 'activity-edition' && activity"
      @cancel="mode = 'type-selection'"
    />

    <MediaInput
      v-if="mode === 'media-input'"
      :type="selectedType.id"
      :configuration="selectedType"
      :media="media"
      @save="$emit('media', $event)"
      @cancel="mode = 'type-selection'"
    >
      <template #extra>
        <div class="mt-4 mb-3">
          <p class="d-inline font-weight-bold mb-1">
            Adicionar uma imagem de capa
            <hs-icon
              v-if="mode != 'media-input' && Boolean(media)"
              id="thumbnail-info"
              class="ml-2"
              icon="info-circle"
              :size="14"
            />
          </p>
          <p v-if="mode == 'media-input' || !Boolean(media)">Personalize a capa de cada uma das suas aulas.</p>

          <b-popover
            v-if="mode != 'media-input' || Boolean(media)"
            triggers="hover"
            target="thumbnail-info"
            custom-class="thumbnail-info-popover"
          >
            Formatos: .jpeg, .jpg ou .png, dimensões ideais 1280 x 720px e 1MB.
          </b-popover>
        </div>

        <div :class="Boolean(coverImage) ? 'mt-4 mb-2' : ''">
          <Thumbnail
            :isLoading="isThumbnailLoading"
            :source="thumbnail"
            :showMessage="mode == 'media-input' || !Boolean(media)"
            @selected-image="$listeners['selected-thumbnail']('uploading', $event)"
            @remove-image="$listeners['remove-thumbnail']"
          />
        </div>
      </template>
    </MediaInput>

    <MediaOverview
      v-if="mode === 'media-overview'"
      :isThumbnailLoading="isThumbnailLoading"
      :media="media"
      :coverImage="coverImage"
      @selected-image="$listeners['selected-thumbnail']('changing', $event)"
      @remove-image="$listeners['remove-thumbnail']"
      @change="mode = 'type-selection'"
      @view="mode = 'media-view'"
      @edit="handleEdit"
    />

    <WebinarHerospark
      v-if="mode === 'media-webinar'"
      class="tlw-px-4"
      :webinar_description="webinar_description"
      @descriptionChanged="webinar_description = $event"
    />

    <div class="mt-4 mb-3" v-if="mode === 'activity-edition' && activity && !Boolean(media)">
      <p class="d-inline font-weight-bold mb-1">Adicionar uma imagem de capa</p>
      <p>Personalize a capa de cada uma das suas aulas.</p>
    </div>

    <div :class="Boolean(coverImage) ? 'mt-4 mb-2' : ''">
      <Thumbnail
        v-if="mode === 'activity-edition' && activity"
        :isLoading="isThumbnailLoading"
        :source="thumbnail"
        :showMessage="!Boolean(media)"
        @selected-image="$listeners['selected-thumbnail']('uploading', $event)"
        @remove-image="$listeners['remove-thumbnail']"
      />
    </div>

    <Question :activity="activity" v-if="mode === 'activity-edition' && activity" @cancel="mode = 'type-selection'" />

    <div v-if="mode === 'media-view'">
      <BackButton @click="mode = 'media-overview'" />
      <MediaViewer class="mb-3" :media="media" />
    </div>
  </div>
</template>

<script>
import debug from 'debug';
import { mapState } from 'vuex';
import BackButton from '../../../BackButton';
import TypeSelector from '../../../TypeSelector';
import ActivityEditor from './components/ActivityEditor';
import Question from './components/ActivityEditor/Questions';
import MediaInput from '@/components/MediaInput';
import WebinarHerospark from '@/components/WebinarHerospark';
import MediaOverview from './components/MediaOverview';
import MediaViewer from '@/components/MediaViewer';
import AvailableMainContent from '@/data/main_content_types';
import Thumbnail from '@/components/Thumbnail';

const logging = debug('hs:lesson-media');

export default {
  name: 'MainContent',
  props: {
    isThumbnailLoading: {
      type: Boolean,
      default: false,
    },
    activity: {
      type: [Object, String],
    },
    coverImage: {
      type: Object,
    },
    media: {
      type: Object,
    },
    lessonKind: {
      type: String,
    },
  },
  data() {
    return {
      mode: 'type-selection',
      webinar_description: '',
      selectedType: null,
      activityType: [
        {
          id: 'activity',
          title: this.$t(
            'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.questionary.title'
          ),
          icon: 'file-signature',
          active: false,
          subTypes: [],
        },
        {
          id: 'webinar',
          title: this.$t(
            'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.questionary.title'
          ),
          icon: 'wifi',
          active: false,
          subTypes: [],
        },
      ],
      types: AvailableMainContent(key => this.$t(key)),
    };
  },
  watch: {
    activity(newValue) {
      if (newValue) this.mode = 'activity-edition';
    },
    media(newValue) {
      if (newValue) this.mode = 'media-overview';
    },
    webinar(newValue) {
      if (newValue) this.mode = 'media-webinar';
    },
  },
  computed: {
    ...mapState('school', {
      schoolId: state => state.selectedSchool.id,
    }),
    thumbnail() {
      return this.coverImage ? this.coverImage.s3_file_url : this.thumbnailImg;
    },
  },
  methods: {
    typeSelected(type) {
      this.selectedType = type;
      if (this.selectedType.id === 'activity') {
        this.$emit('activity');
      } else if (this.selectedType.id === 'webinar') {
        this.mode = 'media-webinar';
      } else this.mode = 'media-input';
    },
    handleBackButton() {
      this.mode = this.activity ? 'activity-edition' : 'media-overview';
    },
    handleEdit() {
      this.selectedType = {
        active: true,
        id: 'text',
        title: this.media.name,
        text: this.media.text,
        icon: 'file-word',
        inputComponent: 'TextInput',
      };
      this.mode = 'media-input';
    },
  },
  components: {
    BackButton,
    TypeSelector,
    ActivityEditor,
    MediaInput,
    MediaOverview,
    MediaViewer,
    Question,
    WebinarHerospark,
    Thumbnail,
  },
  mounted() {
    if (this.activity) this.mode = 'activity-edition';
    else if (this.media) this.mode = 'media-overview';
  },
};
</script>

<style lang="scss">
.thumbnail-info-popover {
  .arrow:after {
    border-left-color: #3f3f3f;
    border-right-color: #3f3f3f;
  }

  .popover-body {
    color: white !important;
    background-color: #3f3f3f;
    font-size: 12px;
  }
}
</style>
