import { lookup as mimeByExtension } from 'mime-types';

export default translate => [
  {
    id: 'video',
    title: translate('sparkmembers.contents.views.editor.components.lesson.components.main-media.types.video.title'),
    icon: 'play',
    active: false,
    subTypes: [
      {
        id: 'youtube',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.video.subtypes.youtube.title'
        ),
        icon: 'play-circle',
        active: false,
      },
      {
        id: 'vimeo',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.video.subtypes.vimeo.title'
        ),
        icon: 'video',
        active: false,
      },
      {
        id: 'sparkVideos',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.video.subtypes.video.title'
        ),
        icon: 'cloud-upload',
        active: false,
        badge: true,
        features: ['members_sparkvideos'],
      },
    ],
  },
  {
    id: 'webinar',
    title: translate('sparkmembers.contents.views.editor.components.lesson.components.main-media.types.webinar.title'),
    icon: 'wifi',
    active: false,
    subTypes: [
      {
        id: 'WebinarHerospark',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.video.subtypes.youtube.title'
        ),
        icon: 'play-circle',
        active: false,
      },
      {
        id: 'WebinarHerospark',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.video.subtypes.vimeo.title'
        ),
        icon: 'video',
        active: false,
      },
      {
        id: 'outro',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.video.subtypes.outro.title'
        ),
        icon: 'file',
        active: false,
      },
    ],
  },
  {
    id: 'activity',
    title: translate(
      'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.questionary.title'
    ),
    icon: 'file-signature',
    active: false,
    subTypes: [],
  },
  {
    id: 'others',
    title: translate('sparkmembers.contents.views.editor.components.lesson.components.main-media.types.others.title'),
    icon: 'file',
    active: false,
    subTypes: [
      {
        id: 'audio',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.others.subtypes.audio.title'
        ),
        icon: 'microphone',
        active: false,
        inputComponent: 'AudioInput',
      },
      {
        id: 'ebook',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.others.subtypes.ebook.title'
        ),
        icon: 'book-alt',
        supportedFiles: [mimeByExtension('.pdf')],
        active: false,
        inputComponent: 'FileInput',
      },
      {
        id: 'file',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.others.subtypes.file.title'
        ),
        icon: 'file',
        supportedFiles: [mimeByExtension('.pdf'), mimeByExtension('.xls'), mimeByExtension('.xlsx'), '.csv'],
        active: false,
        inputComponent: 'FileInput',
      },
      {
        id: 'text',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.others.subtypes.text.title'
        ),
        icon: 'file-word',
        active: false,
        inputComponent: 'TextInput',
      },
      {
        id: 'image',
        title: translate(
          'sparkmembers.contents.views.editor.components.lesson.components.main-media.types.others.subtypes.image.title'
        ),
        icon: 'image',
        active: false,
        inputComponent: 'ImageInput',
      },
    ],
  },
];
